import { jwtDecode } from "jwt-decode";
import axios from "axios";


const IsTokenSet = () => {
    const token = window.localStorage.getItem("extrack_token");
    if (token) {
        return true;
    }
    return false;
}


const IsTokenValid = () => {

    const token = window.localStorage.getItem("extrack_token");

    try {
    
        if (token === "undefined") {
            removeLocalState();
            return false;
        }

        const decoded = jwtDecode(token);
        const now = new Date();
        const nowInSeconds = Math.floor(now.getTime() / 1000);
        if (decoded.exp > nowInSeconds) {
            return true;
        } else {                                // expired token
            removeLocalState();
            return false;
        }
    
    } catch { 
        return false;                           // failed to decode token also counts as invalid
    }

}


const removeLocalState = () => {
    window.localStorage.removeItem("extrack_token");
    window.localStorage.removeItem("logged_in");
    window.localStorage.removeItem("username");
}

const GetUsername = () => {
    const token = window.localStorage.getItem("extrack_token");
    try {
        const decoded = jwtDecode(token);
        if (decoded.sub) {
            return(decoded.sub);
        }
    } catch {
        return false;
    }
}


const RefreshToken = () => {

    const requestOptions = {
        crossDomain: true,
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + window.localStorage.getItem("extrack_token")
        },
    }

    const refreshPayload = {
        grant_type: 'refresh_token',
        refresh_token: window.localStorage.getItem("extrack_token")
    }

    axios.post(process.env.REACT_APP_AUTH_API_ENDPOINT + '/auth/refresh', refreshPayload, requestOptions).then((response) => {
        window.localStorage.setItem("extrack_token", response.data["refresh_token"])
    }).catch(error => {
        return Promise.reject(error);
    });

}

const loginHelpers = {
    IsTokenSet,
    IsTokenValid,
    GetUsername,
    RefreshToken
}

export default loginHelpers
